/* Import Tailwind CSS base styles */
@import 'tailwindcss/base';

/* Import Tailwind CSS components styles */
@import 'tailwindcss/components';

/* Import Tailwind CSS utilities styles */
@import 'tailwindcss/utilities';


body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  /* overflow-x: hidden; */
  cursor: none;

}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@layer components {

  .animate-slide {
    animation: 20s slide linear infinite;
  }
  
  @keyframes slide {
    from {
      transform: translateX(0);
    } to {
      transform: translateX(-100%);
    }
  }

  .cps-un-gold-btn{
    padding: 3px 40px;
    display: flex;
    border: 2px solid #DCA843;
    border-radius: 12px;
    color: #DCA843;
    font-size: 16px;
    height: fit-content;
  }
  .cps-un-silver-btn{
    padding: 3px 40px;
    display: flex;
    border: 2px solid #BABABA;
    border-radius: 12px;
    color: #BABABA;
    font-size: 16px;
    height: fit-content;
  }

  .flex-container {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .box {
    flex: 1;
    min-width: 100px; 
    min-height: 100px; 
    padding: 1rem; 
    background-color: #000; 
    border-radius: 0.5rem; 
    box-shadow: 0px 4px 4px rgba(220, 168, 67, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
  }
  
  .text-2xl {
    font-size: 1.5rem;
    color: #fff; 
    font-weight: bold;
  }
  
  .text-sm {
    font-size: 0.875rem; 
    color: #fff; 
    font-weight: bold;

  }

  .image-track {
    display: flex;
    gap: 4vmin;
    margin-top: 4rem;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    transition: transform 0.5s ease-in-out; /* Transition for container */
}

.image {
    width: 40vmin;
    height: 56vmin;
    object-fit: cover;
    pointer-events: none;
    scroll-snap-align: start;
    transition: object-position 0.5s ease-in-out; /* Transition for images */
}

  
  #container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  
  .image-container {
    flex: 1;
    display: flex;
    height: 350px;
    width: 100%;
  }
  
  .img {
    position: relative;
    width: 100%;
    height: auto;
    background-color: #000;
    background-position: center;
    background-size: cover;
    transition: all 0.5s ease-out;
  }
  
  .img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .img:hover {
    opacity: 1;
    width: 200%;
  }
  
  .img:hover::before {
    opacity: 0;
    transition: all 0.5s ease-out;
  }
  
  .img-label {
    display: none;
    width: 85%;
    height: auto;
    background-color: #fff;
    padding-left: 12px;
    position: absolute;
    top: 80%;
    text-align: left;
    animation: slideIn 0.8s forwards;
  }
  .img-label h2{
    color: black;
    font-weight: bold;
    margin: 0;
  }
  
  .img-label p{
    color: black;
    margin: 0;
  }
  
  
  .img:hover .img-label {
    display: block;
    top: 100;
  }

  .word {
    position: relative;
  }
  .line-mask {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #0d0d0d;
    opacity: 0.8;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
  @keyframes moveImages {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%); 
    }
  }
  .animate-slide {
    animation: 15s slide linear infinite;
  }
}  

@media screen and (max-width: 415px) {
  .box {
    min-height: 65px;
    min-width: 65px;;
  }

  .munpics {
    overflow: scroll;
  }
}